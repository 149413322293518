import * as React from 'react'
import Layout from '../components/layout'

const MeetupsPage = () => {
    return (
        <Layout title="Meetups">
            Meetups page
        </Layout>
    )
}

export default MeetupsPage
